import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useCommonServiceCommonControllerGetAddressByZipCode,
  useEmployerServiceEmployerControllerCreateEmployerAddress,
  useEmployerServiceEmployerControllerGetEmployerAddresses,
  useEmployerServiceEmployerControllerUpdateEmployerAddress,
} from '~/api/queries';
import { AddressDto, ApiError } from '~/api/requests';
import { setError, setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';
import { unflatten } from '~/utils/unformat-value';

type ActionType = 'delete' | 'create' | 'edit';

interface IEnterpriseAddressHook {
  data: AddressDto[];
  actionType?: ActionType;
  modal: ModalState;
  address: AddressDto;
  isButtonDisabled: boolean;
  isGetAddressesLoading: boolean;
  isGetAddressInfoLoading: boolean;
  isActionLoading: boolean;
  isSwitchChecked?: boolean;
  onAction: (type?: ActionType, id?: string) => void;
  onClose: (type?: ActionType) => void;
  toggleSwitchChecker: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleData: (name: string, value: any) => void;
  onSubmit: (type?: ActionType) => Promise<void>;
}

interface ModalState {
  onDelete: boolean;
  onCreate: boolean;
  onEdit: boolean;
}

const INITIAL_ADDRESS_STATE: AddressDto = {
  id: '',
  city: '',
  country: 'BR',
  neighborhood: '',
  number: '',
  state: '',
  street: '',
  zipCode: '',
  complement: '',
  isMain: false,
};

const INITIAL_MODAL_STATE = {
  onDelete: false,
  onCreate: false,
  onEdit: false,
};

export const useEnterpriseAddressHook = (): IEnterpriseAddressHook => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [address, setAddress] = useState<AddressDto>(INITIAL_ADDRESS_STATE);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [enabled, setEnabled] = useState(false);
  const [actionType, setActionType] = useState<ActionType>();

  const { data, isPending, refetch, isRefetching } =
    useEmployerServiceEmployerControllerGetEmployerAddresses();
  const updateAddress = useEmployerServiceEmployerControllerUpdateEmployerAddress();
  const createAddress = useEmployerServiceEmployerControllerCreateEmployerAddress();
  const commomAddress = useCommonServiceCommonControllerGetAddressByZipCode(
    {
      zipCode: unflatten(address.zipCode).length === 8 ? unflatten(address.zipCode) : '',
    },
    undefined,
    { enabled: unflatten(address.zipCode).length === 8 && enabled }
  );

  const toggleSwitchChecker = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };

  useEffect(() => {
    if (commomAddress.data && enabled) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        zipCode: address.zipCode,
        country: 'BR',
        city: commomAddress.data?.city ?? '',
        state: commomAddress.data?.state ?? '',
        neighborhood: commomAddress.data?.neighborhood ?? '',
        street: commomAddress.data?.street ?? '',
        complement: '',
        number: '',
      }));
    }
  }, [commomAddress.data]);

  useEffect(() => {
    if (unflatten(address.zipCode).length === 8 && !commomAddress.data && enabled) {
      setAddress({ ...INITIAL_ADDRESS_STATE, zipCode: address.zipCode, id: address.id });
    }
  }, [address.zipCode, commomAddress.data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleData = (name: string, value: any) => {
    if (name === 'zipCode') setEnabled(true);
    if (name === 'number') value = value?.replace(/\D/g, '');
    if (name === 'state') value = value?.replace(/[^a-zA-Z\s\.]/g, '');
    setAddress({ ...address, [name]: value });
  };

  const onClose = (type?: ActionType) => {
    if (type === 'delete') setModal({ ...modal, onDelete: false });
    if (type === 'create') {
      setAddress(INITIAL_ADDRESS_STATE);
      setModal({ ...modal, onCreate: false });
    }
    if (type === 'edit') {
      setAddress(INITIAL_ADDRESS_STATE);
      setModal({ ...modal, onEdit: false });
    }
    setEnabled(false);
  };

  const onAction = (type?: ActionType, id?: string) => {
    setActionType(type);
    if (type === 'delete') {
      setModal({ ...modal, onDelete: true });
    }
    if (type === 'create') {
      if (data?.length === 0) setAddress({ ...INITIAL_ADDRESS_STATE, isMain: true });
      else setAddress(INITIAL_ADDRESS_STATE);
      setModal({ ...modal, onCreate: true });
    }
    if (type === 'edit') {
      setEnabled(false);
      const address = data?.find((d) => d.id === id) ?? INITIAL_ADDRESS_STATE;
      setAddress(address);
      setModal({ ...modal, onEdit: true });
    }
  };

  const isButtonDisabled = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { id, country, complement, isMain, ...addressWithoutId } = address;

    return Object.values(addressWithoutId).some((value) => !value) || unflatten(address.zipCode).length !== 8;
  };

  const onError = (err: unknown) => {
    const error = err as ApiError;
    setIsSwitchChecked(false);
    dispatch(setError(error.body.code));
    onClose(actionType);
  };

  const onSuccess = () => {
    const value =
      actionType === 'create'
        ? SuccessEnum.CREATED_ENTERPRISE_ADDRESS
        : SuccessEnum.UPDATED_ENTERPRISE_ADDRESS;
    queryClient.invalidateQueries();
    refetch();
    setIsSwitchChecked(false);
    dispatch(setSuccess(value));
    onClose(actionType);
  };

  const onSubmit = async (type?: ActionType) => {
    if (type === 'create')
      createAddress.mutate(
        {
          requestBody: {
            ...address,
            isMain: isSwitchChecked || address.isMain,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    if (type === 'delete') dispatch(setSuccess(SuccessEnum.DELETED_ENTERPRISE_ADDRESS));
    if (type === 'edit')
      updateAddress.mutate(
        {
          requestBody: {
            ...address,
            isMain: isSwitchChecked || address.isMain,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
  };

  return {
    data: data ?? [],
    modal,
    address,
    actionType,
    isButtonDisabled: isButtonDisabled(),
    isGetAddressesLoading: isPending || isRefetching,
    isGetAddressInfoLoading: commomAddress.isPending && unflatten(address.zipCode).length === 8 && enabled,
    isSwitchChecked,
    isActionLoading: updateAddress.isPending || createAddress.isPending,
    onAction,
    onClose,
    handleData,
    onSubmit,
    toggleSwitchChecker,
  };
};
