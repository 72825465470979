import { useQueryClient } from '@tanstack/react-query';
import { formatToCEP, formatToCNPJ, formatToPhone } from 'brazilian-values';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  useCommonServiceCommonControllerGetAddressByZipCode,
  useEmployerServiceEmployerControllerCreateEmployee,
} from '~/api/queries';
import { AddressDto, ApiError, CreateEmployerDto } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { clearSuccess, setError, setSuccess } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';
import { SuccessEnum } from '~/translates/success/types';
import { formatPhone } from '~/utils/format-phone';
import { unflatten } from '~/utils/unformat-value';

import { companyValidationSchema } from './schema';

type BoxType = 'info' | 'address';

interface Error {
  email?: string;
  documentNumber?: string;
  phoneNumber?: string;
}

interface State {
  company: CreateEmployerDto;
  errors: Error;
  isEnabled: boolean;
}

const INITIAL_EMPLOYER_ADDRESS_STATE = {
  zipCode: '',
  country: 'BR',
  city: '',
  state: '',
  neighborhood: '',
  street: '',
  number: '',
};

const INITIAL_STATE: State = {
  company: {
    documentNumber: '',
    email: '',
    legalName: '',
    phoneNumber: '',
  } as CreateEmployerDto,
  errors: {},
  isEnabled: true,
};

interface INewCompanyHook {
  company: CreateEmployerDto;
  errors: Error;
  isGettingAddress: boolean;
  isButtonDisabled: boolean;
  isCreateCompanyLoading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  onSuccessPress: () => Promise<void>;
  handleData: (box: BoxType, name: string, value?: string) => void;
}

export const useNewCompanyHook = (): INewCompanyHook => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState<State>(INITIAL_STATE);
  const [address, setAddress] = useState<AddressDto>(INITIAL_EMPLOYER_ADDRESS_STATE);
  const zipCode = unflatten(address?.zipCode);

  const onCreateCompany = useEmployerServiceEmployerControllerCreateEmployee({
    onSuccess: () => {
      queryClient.invalidateQueries();
      dispatch(setSuccess(SuccessEnum.CREATE_COMPANY));
    },
    onError: (err) => {
      const error = err as ApiError;

      if (
        error.body.errorType === ErrorsEnum.UNPROCESSABLE_ENTITY &&
        error.body.message.includes('phoneNumber')
      )
        dispatch(setError(ErrorsEnum.INVALID_EMPLOYER_PHONE));
      else {
        if (error.body.code === ErrorsEnum.EMPLOYER_ALREADY_EXISTS) dispatch(setError(error.body.code));
        else dispatch(setError(error.body.errorType));
      }
    },
  });

  const onGetCommonAddress = useCommonServiceCommonControllerGetAddressByZipCode(
    {
      zipCode: zipCode?.length === 8 ? zipCode : '',
    },
    undefined,
    { enabled: zipCode?.length === 8 && state.isEnabled }
  );

  useEffect(() => {
    if (onGetCommonAddress.data && state.isEnabled) {
      setAddress({
        ...address,
        zipCode: address.zipCode,
        city: onGetCommonAddress.data.city,
        state: onGetCommonAddress.data.state,
        neighborhood: onGetCommonAddress.data.neighborhood,
        street: onGetCommonAddress.data.street,
        number: '',
        complement: '',
      });
    }
  }, [onGetCommonAddress.data, state.isEnabled]);

  useEffect(() => {
    if (zipCode.length === 8 && !onGetCommonAddress.data && state.isEnabled) {
      setAddress({
        ...INITIAL_EMPLOYER_ADDRESS_STATE,
        zipCode: address.zipCode,
      });
    }
  }, [address.zipCode, onGetCommonAddress.data]);

  useEffect(() => {
    const validation = companyValidationSchema.safeParse(state.company);
    if (!validation.success) {
      const { documentNumber, email, phoneNumber } = validation.error.flatten().fieldErrors;
      setState({
        ...state,
        errors: {
          email: state.company.email && email?.[0],
          documentNumber: state.company.documentNumber && documentNumber?.[0],
          phoneNumber: state.company.phoneNumber && phoneNumber?.[0],
        },
      });
    } else {
      setState({ ...state, errors: {} });
    }
  }, [state.company]);

  const toFormatContent = (name: string, value?: string): string => {
    if (name === 'number') value = value?.replace(/\D/g, '');
    if (name === 'state') value = value?.replace(/[^a-zA-Z\s\.]/g, '');
    if (name === 'zipCode') {
      setState({ ...state, isEnabled: true });
      value = formatToCEP(value ?? '');
    }
    if (name === 'phoneNumber') value = formatToPhone(value ?? '');
    if (name === 'documentNumber') value = formatToCNPJ(value ?? '');

    return value ?? '';
  };

  const handleData = (box: BoxType, name: string, value?: string) => {
    if (box === 'info')
      setState({ ...state, company: { ...state.company, [name]: toFormatContent(name, value) } });
    if (box === 'address') setAddress({ ...address, [name]: toFormatContent(name, value) });
  };

  const isButtonDisabled = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { id, country, complement, isMain, ...addressWithoutId } = address || {};

    const hasEmptyFields = Object.values(state.company).some((value) => value === '' || !value);
    const hasEmptyAddressFields =
      Object.values(addressWithoutId).some((value) => !value) || unflatten(address?.zipCode).length !== 8;
    const hasErrors = Object.values(state.errors).some((error) => error);

    return hasEmptyFields || hasErrors || hasEmptyAddressFields;
  };

  const onSubmit = async () => {
    try {
      await onCreateCompany.mutateAsync({
        requestBody: {
          ...state.company,
          phoneNumber: formatPhone(state.company.phoneNumber),
          documentNumber: unflatten(state.company.documentNumber),
          address: {
            ...address,
            zipCode: unflatten(address.zipCode),
          },
        },
      });
    } catch (error) {}
  };

  const onSuccessPress = async () => {
    dispatch(clearSuccess());
    navigate(ISSUER_SCREENS_DEFINITIONS.list);
  };

  const onCancel = () => navigate(-1);

  return {
    company: { ...state.company, address },
    errors: state.errors,
    isGettingAddress: onGetCommonAddress.isPending && zipCode.length === 8 && state.isEnabled,
    isButtonDisabled: isButtonDisabled(),
    isCreateCompanyLoading: onCreateCompany.isPending,
    onSubmit,
    onCancel,
    onSuccessPress,
    handleData,
  };
};
