import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useLoadOrderServiceOrderControllerCancelOrder,
  useLoadOrderServiceOrderControllerGetOrderDetailsViaEmail,
  useLoadOrderServiceOrderControllerGetStatementPdfViaEmail,
} from '~/api/queries';
import { ApiError } from '~/api/requests';
import { setError } from '~/redux/reducers/application';

interface IUseRechargeDetails {
  isSendEmailModalVisible: boolean;
  toggleSendEmailModal: () => void;
  isSendEmailLoading: boolean;
  sendEmail: () => void;
  isSuccessModalVisible: boolean;
  toggleSuccessModal: () => void;
  isCanceledModalVisible: boolean;
  toggleCanceledModal: () => void;
  isCancelOrderLoading: boolean;
  handleCancelOrder: () => void;
  isSendStatementModalVisible: boolean;
  toggleSendStatementModal: () => void;
  sendStatement: () => void;
  isSendStatementLoading: boolean;
}

export const UseRechargeDetails = (orderId: string): IUseRechargeDetails => {
  const dispatch = useDispatch();
  const [isSendEmailModalVisible, setIsSendEmailModalVisible] = useState(false);
  const [isSendEmailEnabled, setIsSendEmailEnabled] = useState(false);
  const [isSendEmailHookEnabled, setIsSendEmailHookEnabled] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isSendEmailLoading, setIsSendEmailLoading] = useState(false);
  const [isCanceledModalVisible, setIsCanceledModalVisible] = useState(false);
  const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);
  const [isSendStatementModalVisible, setIsSendStatementModalVisible] = useState(false);
  const [isSendStatementEnabled, setIsSendStatementEnabled] = useState(false);
  const [isSendStatementHookEnabled, setIsSendStatementHookEnabled] = useState(false);
  const [isSendStatementLoading, setIsSendStatementLoading] = useState(false);

  const sendEmailHook = useLoadOrderServiceOrderControllerGetOrderDetailsViaEmail(
    {
      id: orderId,
    },
    undefined,
    {
      enabled: isSendEmailHookEnabled,
    }
  );

  const sendStatementHook = useLoadOrderServiceOrderControllerGetStatementPdfViaEmail(
    {
      id: orderId,
    },
    undefined,
    {
      enabled: isSendStatementHookEnabled,
    }
  );

  const cancelOrder = useLoadOrderServiceOrderControllerCancelOrder({
    onSuccess: () => {
      setIsCanceledModalVisible(true);
      setIsCancelOrderLoading(false);
    },
    onError: (e: ApiError) => {
      dispatch(setError(e.message));
      setIsCancelOrderLoading(false);
    },
    onMutate: () => {
      setIsCancelOrderLoading(true);
    },
  });

  const handleCancelOrder = () => {
    cancelOrder.mutate({ id: orderId });
  };

  useEffect(() => {
    const handleRefetch = async () => {
      await sendEmailHook.refetch().then(() => {
        if (sendEmailHook.isSuccess) {
          setIsSendEmailModalVisible(false);
          setIsSuccessModalVisible(true);
        }
        setIsSendEmailLoading(false);
      });
    };

    if (isSendEmailEnabled && isSendEmailHookEnabled) {
      handleRefetch();
      setIsSendEmailEnabled(false);
    }
  }, [isSendEmailLoading]);

  useEffect(() => {
    const handleRefetch = async () => {
      await sendStatementHook.refetch().then(() => {
        if (sendStatementHook.isSuccess) {
          setIsSendStatementModalVisible(false);
        }
        setIsSendStatementLoading(false);
      });
    };

    if (isSendStatementEnabled && isSendStatementHookEnabled) {
      handleRefetch();
      setIsSendStatementEnabled(false);
    }
  }, [isSendStatementLoading]);

  const sendEmail = () => {
    setIsSendEmailLoading(true);
    if (!isSendEmailHookEnabled) {
      setIsSendEmailHookEnabled(true);
      setIsSendEmailModalVisible(false);
      setIsSuccessModalVisible(true);
      setIsSendEmailLoading(false);

      return;
    }
    setIsSendEmailEnabled(true);
  };

  const sendStatement = () => {
    setIsSendStatementLoading(true);
    if (!isSendStatementHookEnabled) {
      setIsSendStatementHookEnabled(true);
      setIsSendStatementModalVisible(false);
      setIsSuccessModalVisible(true);
      setIsSendStatementLoading(false);

      return;
    }
    setIsSendStatementEnabled(true);
  };

  return {
    isSendEmailModalVisible,
    toggleSendEmailModal: () => setIsSendEmailModalVisible(!isSendEmailModalVisible),
    isSendEmailLoading,
    sendEmail,
    isSuccessModalVisible,
    toggleSuccessModal: () => setIsSuccessModalVisible(!isSuccessModalVisible),
    isCanceledModalVisible,
    toggleCanceledModal: () => setIsCanceledModalVisible(!isCanceledModalVisible),
    isCancelOrderLoading,
    handleCancelOrder,
    isSendStatementLoading,
    isSendStatementModalVisible,
    sendStatement,
    toggleSendStatementModal: () => setIsSendStatementModalVisible(!isSendStatementModalVisible),
  };
};
