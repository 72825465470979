export const ptBrIssuerPageTranslations = {
  issuer: {
    home: {
      company: {
        pageHeader: {
          newEmployer: 'Cadastrar nova empresa',
          subtitle: 'Últimas empresas cadastradas',
        },
      },
      pageHeader: {
        currentStep: 'Home Portal Benefícios',
        subtitle: 'Boas-vindas!',
      },
      empty: {
        title: 'Comece por aqui',
        message: 'Cadastre sua primeira empresa RH',
      },
    },
    company: {
      list: {
        pageHeader: {
          currentStep: 'Empresas RH',
          subtitle: 'Lista de empresas RH',
          trailingButton: {
            label: 'Cadastrar empresa',
          },
        },
        search: {
          placeholder: 'Buscar por CNPJ',
        },
      },
      details: {
        pageHeader: {
          trailingButton: {
            label: 'Acessar Portal RH',
          },
        },
        info: {
          title: 'Informações da empresa',
          fields: {
            legalName: 'Razão social',
            document: 'CNPJ',
            createdAt: 'Data do cadastro',
          },
        },
        contact: {
          title: 'Contato',
          fields: {
            email: 'E-mail',
            phone: 'Telefone',
          },
        },
        address: {
          title: 'Endereço',
          fields: {
            zipCode: 'CEP',
            street: 'Rua',
            country: 'País',
            neighborhood: 'Bairro',
            number: 'Número',
            complement: 'Complemento',
            city: 'Cidade',
            state: 'Estado',
          },
        },
      },
      new: {
        pageHeader: {
          currentStep: 'Nova empresa',
          subtitle: 'Cadastrar nova empresa',
        },
        info: {
          fields: {
            legalName: 'Razão social',
            documentNumber: 'CNPJ',
            email: 'E-mail',
            phoneNumber: 'Telefone',
          },
        },
        contact: {
          title: 'Contato',
        },
        address: {
          title: 'Endereço',
          fields: {
            zipCode: 'CEP',
            street: 'Rua',
            country: 'País',
            neighborhood: 'Bairro',
            number: 'Número',
            complement: 'Complemento',
            city: 'Cidade',
            state: 'Estado',
          },
        },
        button: {
          cancel: 'Cancelar',
          create: 'Criar',
        },
      },
    },
    permissions: {
      pageHeader: {
        title: 'Permissões',
        button: 'Adicionar permissão',
      },
      fields: {
        name: 'Nome completo',
        document: 'CPF',
        email: 'E-mail',
        profile: 'Perfil',
      },
      button: {
        cancel: 'Cancelar',
        send: 'Enviar',
      },
    },
  },
};
