import { AddressDto, CreateEmployerDto } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { SuccessEnum } from '~/translates/success/types';
import { useTranslation } from '~/translates/use-translate';

import {
  ButtonRow,
  Column,
  PageHeader,
  PrimaryButton,
  Screen,
  Section,
  Skeleton,
  TertiaryButton,
  TextField,
  TextFieldRow,
  TextFieldWrapper,
  Title,
} from './new-company.styles';

type BoxType = 'info' | 'address';

interface Error {
  email?: string;
  documentNumber?: string;
  phoneNumber?: string;
}

interface INewCompanyLayout {
  company: CreateEmployerDto;
  errors: Error;
  isGettingAddress: boolean;
  isButtonDisabled: boolean;
  isCreateCompanyLoading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  onSuccessPress: () => Promise<void>;
  handleData: (box: BoxType, name: string, value?: string) => void;
}

type EmployerType = Omit<CreateEmployerDto, 'address'>;
type PartialAddress = Omit<AddressDto, 'isMain'>;

export const NewCompanyLayout = ({
  company,
  errors,
  isButtonDisabled,
  isCreateCompanyLoading,
  isGettingAddress,
  onSubmit,
  onCancel,
  onSuccessPress,
  handleData,
}: INewCompanyLayout) => {
  const translate = useTranslation('pages.issuer.company.new');
  const success = useTranslation('success');

  const renderTextField = (box: BoxType, name: string) => {
    const loadFields = ['street', 'neighborhood', 'city', 'state'];
    const disabledFields = ['city', 'state'];

    const isDisabled = disabledFields.includes(name);

    const value = {
      contact: company?.[name as keyof EmployerType],
      address: company.address?.[name as keyof PartialAddress],
      info: company?.[name as keyof EmployerType],
    };

    const maxLength: { [key: string]: number } = {
      documentNumber: 18,
      phoneNumber: 16,
      zipCode: 9,
    };

    if (loadFields.includes(name) && isGettingAddress) return <Skeleton type={name} hasMargin />;

    return (
      <Column type={name}>
        <TextField
          type={name}
          label={translate(`${box}.fields.${name}`)}
          onChange={(value: string) => handleData(box, name, value)}
          onClearTextField={() => handleData(box, name, undefined)}
          value={value[box] ?? ''}
          maxLength={maxLength?.[name]}
          error={errors?.[name as keyof Error]}
          isDisabled={isDisabled}
        />
      </Column>
    );
  };

  const renderPageHeader = () => (
    <PageHeader leadingText={translate('pageHeader.currentStep')} title={translate('pageHeader.subtitle')} />
  );

  const renderSectionTitle = (box: string) => <Title>{translate(`${box}.title`)}</Title>;

  const renderCompanyInfo = () => {
    const fields = ['legalName', 'documentNumber'];

    return (
      <TextFieldWrapper>
        <TextFieldRow>{fields.map((field) => renderTextField('info', field))}</TextFieldRow>
      </TextFieldWrapper>
    );
  };

  const renderCompanyContact = () => {
    const fields = ['email', 'phoneNumber'];

    return (
      <TextFieldWrapper>
        <TextFieldRow>{fields.map((field) => renderTextField('info', field))}</TextFieldRow>
      </TextFieldWrapper>
    );
  };

  const renderCompanyAddressContent = () => {
    const fields = [
      ['zipCode', 'street', 'complement'],
      ['number', 'neighborhood', 'city', 'state'],
    ];

    return (
      <TextFieldWrapper>
        {fields.map((field) => (
          <TextFieldRow>{field.map((data) => renderTextField('address', data))}</TextFieldRow>
        ))}
      </TextFieldWrapper>
    );
  };

  const renderCompanyInfoSection = () => <Section>{renderCompanyInfo()}</Section>;

  const renderCompanyContactSection = () => (
    <Section>
      {renderSectionTitle('contact')}
      {renderCompanyContact()}
    </Section>
  );

  const renderCompanyAddressSection = () => (
    <Section>
      {renderSectionTitle('address')}
      {renderCompanyAddressContent()}
    </Section>
  );

  const renderButtons = () => (
    <ButtonRow>
      <TertiaryButton label={translate('button.cancel')} onClick={onCancel} />
      <PrimaryButton
        label={translate('button.create')}
        onClick={onSubmit}
        isDisabled={isButtonDisabled}
        isLoading={isCreateCompanyLoading}
      />
    </ButtonRow>
  );

  const secondaryButtonProps = {
    label: success(`${SuccessEnum.CREATE_COMPANY}.button`),
    onClick: onSuccessPress,
  };

  return (
    <Screen>
      {renderPageHeader()}
      {renderCompanyInfoSection()}
      {renderCompanyContactSection()}
      {renderCompanyAddressSection()}
      {renderButtons()}

      <SuccessDialog secondaryButtonProps={secondaryButtonProps} />
      <ErrorDialog />
    </Screen>
  );
};
