export const ProfileEnum = {
  MANAGER: 'manager',
  CLIENT_CONSULTANT: 'client_consultant',
  COMPANY_RH: 'company_rh',
} as const;

export const RoleEnum = {
  ADMIN: 'admin',
  OPERATOR: 'operator',
  VIEWER: 'viewer',
} as const;

export const ScopeEnum = {
  CREATE_USER: 'create_user',
  LIST_USER: 'list_user',
  UPDATE_PERMISSION_USER: 'update_permission_user',
  UPDATE_USER: 'update_user',
  DELETE_USER: 'delete_user',
  RESEND_INVITE_USER: 'resend_invite_user',
  CREATE_ADDRESS_EMPLOYER: 'create_address_employer',
  CREATE_EMPLOYER: 'create_employer',
  LIST_EMPLOYER: 'list_employer',
  GET_EMPLOYER: 'get_employer',
  LIST_ADDRESS_EMPLOYER: 'list_address_employer',
  DELETE_ADDRESS_EMPLOYER: 'delete_address_employer',
  UPDATE_ADDRESS_EMPLOYER: 'update_address_employer',
  ADD_EMPLOYEE: 'add_employee',
  CREATE_EMPLOYEE: 'create_employee',
  LIST_EMPLOYEE: 'list_employee',
  VIEW_PROFILE_EMPLOYEE: 'view_profile_employee',
  UPDATE_STATUS_EMPLOYEE: 'update_status_employee',
  UPDATE_EMPLOYEE: 'update_employee',
  CREATE_ORDER: 'create_order',
  LIST_ORDER: 'list_order',
  VIEW_DETAIL_ORDER: 'view_detail_order',
  CANCEL_ORDER: 'cancel_order',
  DOWNLOAD_RECEIPT: 'download_receipt',
  SPLIT_ORDER: 'split_order',
  REQUEST_CARDS: 'request_cards',
  LIST_REQUEST_CARDS: 'list_request_cards',
  VIEW_EMPLOYER_BASIC_INFO: 'view_employer_basic_info',
} as const;

export const ErrorsEnum = {
  AUTHENTICATION_FAILED: 'authentication_failed',
  ACCESS_CODE_NOT_EXISTS: 'access_code_not_exists',
  EXPIRED_ACCESS_CODE: 'expired_access_code',
  INVALID_PASSWORD_POLICY: 'invalid_password_policy',
  PASSWORD_UPDATE_FAIL: 'password_update_fail',
  ACCOUNT_ALREADY_EXISTS: 'account_already_exists',
  TOKEN_WITHOUT_SCOPES: 'token_without_scopes',
  UNAUTHORIZED_USER: 'unauthorized_user',
  EXPIRED_TOKEN: 'expired_token',
  ZIPCODE_NOT_FOUND: 'zipcode_not_found',
  MUST_CONTAIN_EIGHT_DIGITS: 'must_contain_eight_digits',
  INCONSISTENT_ZIPCODE_LIB: 'inconsistent_zipcode_lib',
  EMAIL_NOT_SENT: 'email_not_sent',
  DOCK_ONE_ERROR: 'dock_one_error',
  DOCK_ONE_BAD_REQUEST: 'dock_one_bad_request',
  DOCK_ONE_NOT_FOUND: 'dock_one_not_found',
  DOCK_ONE_UNPROCESSABLE_ENTITY: 'dock_one_unprocessable_entity',
  VALIDATION_INVALID: 'validation_invalid',
  DOCUMENT_IS_REQUIRED: 'document_is_required',
  TENANT_ID_IS_REQUIRED: 'tenant_id_is_required',
  VALIDATION_REQUIRED: 'validation_required',
  USER_EMAIL_UNIQUE: 'user_email_unique',
  USER_DOCUMENT_NUMBER_UNIQUE: 'user_document_number_unique',
  USER_NOT_FOUND: 'user_not_found',
  CANNOT_DELETE_THE_USER: 'cannot_delete_the_user',
  UNCREATED_CODE: 'uncreated_code',
  UNKNOWN_DATABASE_ERROR: 'unknown_database_error',
  DELETE_USER_IN_FIREBASE: 'delete_user_in_firebase',
  CANNOT_ASSOCIATE_PRIMARY_TENANT: 'cannot_associate_primary_tenant',
  UNAUTHORIZED_ACTION: 'unauthorized_action',
  UNAUTHORIZED_ASSOCIATION: 'unauthorized_association',
  UNAUTHORIZED_DISSOCIATION: 'unauthorized_dissociation',
  BATCH_PROPOSAL_NO_FILE: 'batch_proposal_no_file',
  BATCH_PROPOSAL_EMPTY_FILE: 'batch_proposal_empty_file',
  BATCH_PROPOSAL_INVALID_FILE: 'batch_proposal_invalid_file',
  BATCH_PROPOSAL_EMPTY_COLUMNS: 'batch_proposal_empty_columns',
  BATCH_PROPOSAL_INVALID_COLUMNS: 'batch_proposal_invalid_columns',
  BATCH_PROPOSAL_MAX_BATCH_SIZE: 'batch_proposal_max_batch_size',
  BATCH_PROPOSAL_MIN_BATCH_SIZE: 'batch_proposal_min_batch_size',
  BATCH_PROPOSAL_FILE_WITHOUT_ERRORS: 'batch_proposal_file_without_errors',
  BATCH_PROPOSAL_NOT_FOUND_AT_DATABASE: 'batch_proposal_not_found_at_database',
  BATCH_PROPOSAL_NO_ERRORS_OR_REFUSED: 'batch_proposal_no_errors_or_refused',
  BATCH_PROPOSAL_EMPTY_ROW: 'batch_proposal_empty_row',
  LOAD_ORDER_NO_FILE: 'load_order_no_file',
  LOAD_ORDER_EMPTY_FILE: 'load_order_empty_file',
  LOAD_ORDER_INVALID_EXTENSION: 'load_order_invalid_extension',
  LOAD_ORDER_EMPTY_COLUMNS: 'load_order_empty_columns',
  LOAD_ORDER_INVALID_COLUMNS: 'load_order_invalid_columns',
  LOAD_ORDER_MIN_ITEMS: 'load_order_min_items',
  LOAD_ORDER_MAX_ITEMS: 'load_order_max_items',
  LOAD_ORDER_INVALID_VALUES: 'load_order_invalid_values',
  LOAD_ORDER_CPF_NOT_FOUND: 'load_order_cpf_not_found',
  LOAD_ORDER_DUPLICATED_CPF: 'load_order_duplicated_cpf',
  ORDER_EMPTY_ROW: 'order_empty_row',
  ORDER_INVALID_SPLIT_SCHEDULE_DATE: 'order_invalid_split_schedule_date',
  ORDER_SPLIT_SCHEDULE_DATE_BEFORE_TODAY: 'order_split_schedule_date_before_today',
  ORDER_SPLIT_SCHEDULE_DATE_INVALID_TED: 'order_split_schedule_date_invalid_ted',
  EMPLOYEE_NOT_FOUND: 'employee_not_found',
  ADDRESS_LIMIT_REACHED: 'address_limit_reached',
  ADDRESS_NOT_FOUND: 'address_not_found',
  EMPLOYER_NOT_FOUND: 'employer_not_found',
  EMPLOYER_DOCUMENT_NOT_FOUND: 'employer_document_not_found',
  EMPLOYER_ACCOUNT_NOT_FOUND: 'employer_account_not_found',
  EMPLOYER_ALREADY_EXISTS: 'employer_already_exists',
  ORDER_ALREADY_PROCESSED: 'order_already_processed',
  ORDER_NOT_FOUND: 'order_not_found',
  ORDER_PROCESSING: 'order_processing',
  MODE_DOES_NOT_ALLOW_SPLIT: 'mode_does_not_allow_split',
  STATUS_DOES_NOT_ALLOW_SPLIT: 'status_does_not_allow_split',
  CARDS_QUANTITY_MAX: 'cards_quantity_max',
  CARDS_QUANTITY_MIN: 'cards_quantity_min',
  CARDS_EXPIRATION_DATE_TOO_EARLY: 'cards_expiration_date_too_early',
} as const;

export const TemplateEmailEnum = {
  FIRST_ACCESS: 'first_access',
  FORGOT_PASSWORD: 'forgot_password',
} as const;

export const BatchProposalStatusEnum = {
  PROCESSING: 'processing',
  FAILED: 'failed',
  PARTIAL_PROCESSED: 'partial_processed',
  PROCESSED: 'processed',
} as const;

export const OnboardingStatusEnum = {
  PROCESSING: 'processing',
  APPROVED: 'approved',
  DECLINED: 'declined',
  NOT_PROCESSED: 'not_processed',
} as const;

export const OrderEnum = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export const StatusEmployeeEnum = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  UNKNOWN: 'unknown',
  PENDING: 'pending',
} as const;

export const GenderEmployeeEnum = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
  UNKNOWN: 'unknown',
} as const;

export const MaritalStatusEmployeeEnum = {
  SINGLE: 'single',
  MARRIED: 'married',
  DIVORCED: 'divorced',
  WIDOWED: 'widowed',
  UNKNOWN: 'unknown',
} as const;

export const SplitModeEnum = {
  MANUAL: 'manual',
  INSTANTANEOUS: 'instantaneous',
  SCHEDULED: 'scheduled',
} as const;

export const CreateOrderColumnsTranslatedEnum = {
  REFEICAO_ALIMENTACAO: 'refeicao_alimentacao',
  ALIMENTACAO: 'alimentacao',
  REFEICAO: 'refeicao',
  CULTURA: 'cultura',
  COMBUSTIVEL: 'combustivel',
  TRANSPORTE: 'transporte',
  MOBILIDADE: 'mobilidade',
  VIAGENS: 'viagens',
  LAZER: 'lazer',
  EDUCACAO: 'educacao',
  SAUDE: 'saude',
  HOME_OFFICE: 'home_office',
  GERAL: 'geral',
} as const;

export const CreateOrderErrorMessageEnum = {
  DUPLICATED_CPF: 'duplicated_cpf',
  INVALID_VALUE: 'invalid_value',
  NOT_FOUND_CPF: 'not_found_cpf',
} as const;

export const OrderStatusEnum = {
  PROCESSING_SPREADSHEET: 'processing_spreadsheet',
  SPREADSHEET_FAILURE: 'spreadsheet_failure',
  PENDING_PAYMENT: 'pending_payment',
  CANCELED: 'canceled',
  READY_TO_RELEASE: 'ready_to_release',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  PARTIAL_PROCESSED: 'partial_processed',
  FAILED: 'failed',
  UNKNOWN: 'unknown',
} as const;

export const SortLegalPersonEnum = {
  PERSON_ID: 'person_id',
  EXTERNAL_ID: 'external_id',
  STATUS_ID: 'status_id',
  ONBOARDING_LEVEL: 'onboarding_level',
  LEGAL_NAME: 'legal_name',
  LEGAL_NATURE: 'legal_nature',
  TRADE_NAME: 'trade_name',
  BUSINESS_CLASSIFICATION: 'business_classification',
  INDUSTRIAL_CLASSIFICATION: 'industrial_classification',
  CREATION_DATE: 'creation_date',
  ESTABLISH_DATE: 'establish_date',
} as const;

export const StatusEmployerEnum = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  UNKNOWN: 'unknown',
} as const;

export const CardBatchSortEnum = {
  STATUS: 'status',
  IS_MULTIAPP: 'is_multiapp',
  QUANTITY: 'quantity',
  CREATION_DATE: 'creation_date',
  UPDATE_DATE: 'update_date',
} as const;

export const CardBatchStatusEnum = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  ERROR: 'error',
  PROCESSED: 'processed',
  ABORTED: 'aborted',
} as const;

